import './App.css';

function App() {

const source = "source".split("").map(x => <span className="colorPlx">{x}</span>)

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          {source}<span>ful</span></h1>
      </header>
      <a className="contact" href="mailto:niklas@emevi.se">niklas@emevi.se</a>
    </div>
  );
}

export default App;
